import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function MilfordSoundDayTourFromQueenstown(props) {
  return (
    <>
      <SEO title="Milford Sound Day Tour From Queenstown - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/sliders/milfordsound-tourist-waterfall.jpg")}
                alt=""
                class="first image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/milfordsound-bus-lookout.jpg")}
                alt=""
                class=" image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/milfordsound-wildlife.jpg")}
                alt=""
                class=" image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/tours/awesomenz-milford-sound-cruise-waterfall.jpg")}
                alt=""
                class="last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={true}
        hasSliderBlurLarge={true}
        content={
          <>
            <BookingForm tourCode="A76L" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Milford Sound Day Tour</h1>
                <h3>
                  Experience stunning scenery on an unforgettable day trip to
                  Milford Sound
                </h3>
                <div class="highlights">
                  <ul>
                    <li>1 hour 45 minute cruise</li>
                    <li>Glass roof coaches with FREE Wi-Fi</li>
                    <li>FREE delicious lunch</li>
                    <li>FREE hotel pick ups and drop-offs</li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    <h2 class="tour"> </h2>
                    <p>
                      <strong>UPDATE:</strong>
                      <span>
                        &nbsp;
                        <em>
                          The Milford Road is now open to tour coaches
                          travelling in convoy.&nbsp;There will be limited photo
                          stops on the way to Milford Sound: Mirror Lakes and
                          Eglinton Valley will be the only planned stops. If
                          time allows we may make additional stops.
                        </em>
                      </span>
                    </p>
                    <p>
                      Visit one of New Zealand's unmissable destinations with an{" "}
                      <strong>awesomeNZ</strong> <strong>Milford Sound</strong>{" "}
                      tour, departing daily from Queenstown and including a
                      cruise on the fiord. Our experienced local guides will
                      keep you entertained during your tour, giving you plenty
                      of opportunities for photo stops and making sure you have
                      a truly awesome Milford Sound experience!
                    </p>
                    <p>
                      Set off from <strong>Queenstown</strong> in the morning,
                      following in the path of the ancient Māori who used to
                      travel to Milford Sound in search of precious pounamu
                      (greenstone) more than 1,000 years ago. The road to
                      Milford Sound has some of the world's most spectacular
                      scenery - enjoy panoramic views of{" "}
                      <strong>Fiordland National Park</strong> along the way
                      from our glass-roof coach and hear about the history of
                      this special part of the world.
                    </p>
                    <p>
                      Stop for a bite to eat in Te Anau, before driving along
                      the shores of Lake Te Anau and through the{" "}
                      <strong>Eglinton Valley</strong>, where you'll see an
                      ancient alpine forest, massive waterfalls and the famous{" "}
                      <strong>Mirror Lakes</strong>. Make the most of these
                      magical photo opportunities before our Milford Sound tour
                      continues, taking you through the Upper Hollyford Valley
                      and past the stunning Christie Falls.
                    </p>
                    <p>
                      After passing through the 1.2km long{" "}
                      <strong>Homer Tunnel</strong> (drilled through the solid
                      rock of a mountain range), we enter the breathtaking
                      Cleddau Valley and then descend into{" "}
                      <strong>Milford Sound</strong>. Here you'll jump on board
                      a cruise boat and tuck in to a{" "}
                      <strong>FREE delicious lunch</strong> as your Milford
                      Sound cruise begins.
                    </p>
                    <p>
                      Cruise along the fiord and take in the famous sights and
                      landmarks of Milford Sound, like the uniquely-shaped{" "}
                      <strong>Mitre Peak</strong>, glacier-scarred rock walls
                      that rise 1,200m vertically from the sea. Keep an eye out
                      for marine life like dolphins, seals and penguins too! The
                      fiord is dotted with waterfalls that tumble through the
                      valleys and crash into the sound far below, making the
                      scenery in Milford Sound spectacular, rain or shine.
                      <br />
                      <br />
                      Following the cruise your guide will be waiting to drive
                      you back to Queenstown, arriving in the evening after your
                      full day tour of this true wonder of the world.
                      Alternatively, book a scenic flight back to Queenstown
                      (optional extra, conditions permitting) and soar your way
                      over spectacular scenery. A shuttle will meet you at the
                      airport and return you to your accommodation.
                    </p>
                    <div id="Summary" class="row">
                      <h2 class="tour">FREE Lunch</h2>
                      <p>
                        With every cruise you'll receive a delicious free lunch,
                        served fresh and hot! Choose from:
                      </p>
                      <ul>
                        <li>
                          Pizza Pie (sesame and soy chicken with cabbage,
                          carrots, garlic and ginger)
                        </li>
                        <li>
                          Little India Meal (Turmeric and cumin spiced lentil
                          and potato curry with steamed vegetables and rice -
                          vegetarian, gluten free and dairy free)
                        </li>
                      </ul>
                      <p>
                        Your driver guide will take your order on the day and
                        your meal will be delivered to you when you board your
                        cruise.
                      </p>
                    </div>

                    <div class="clear"></div>
                  </div>
                </div>
              </div>
              <div class="GSRelatedProducts clearfix">
                <h2 class="tour">Tours You May Also Like</h2>
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/milford-sound-tours/sundowner-tour-from-queenstown">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/tours/awesomenz-milford-sound-sundowner-cruise.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Milford Sound Sundowner Tour</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">from </span>{" "}
                      <span class="tour-details-prices-price">$139.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/sundowner-tour-from-queenstown">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-from-te-anau">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/thumbs/milford_ex_te_anau.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Milford Sound Tour From Te Anau</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">from </span>{" "}
                      <span class="tour-details-prices-price">$165.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-from-te-anau">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default MilfordSoundDayTourFromQueenstown
